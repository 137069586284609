<template>
	<div>
		<page-title
			:heading="$t('reservation.lang_editNewType')"
			show-previous-button
			:subheading="this.itemName"
			:icon="icon"
		></page-title>
		<div class="app-main__inner">
			<EditType @headerName="setItemName" />
		</div>
	</div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import EditType from "../../../components/reservation/settings/EditType.vue";

export default {
	components: {
		PageTitle,
		EditType,
	},
	props: ["id"],
	data: () => ({
		itemName: "",
		icon: "pe-7s-plugin icon-gradient bg-tempting-azure",
	}),
	methods: {
		setItemName(itemName) {
			this.itemName = itemName;
		},
	},
};
</script>
